<template>
<div id="analyservenday">
  <b-field grouped group-multiline>
    <date-quick @change="onDateQuick" expanded/>
    <b-datepicker
      placeholder="开始日期"
      v-model="fromDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar-today"
      expanded
      @input="onDateInput">
    </b-datepicker>
    <b-datepicker
      placeholder="截止日期"
      v-model="toDate"
      :min-date="minDate"
      :max-date="maxDate"
      :date-formatter="formatDate"
      icon="calendar"
      expanded
      @input="onDateInput">
    </b-datepicker>
  </b-field>
  <b-field>
    <channel-selector2 ref="channelSelector" @change="onChannelChange"/>
  </b-field>
  <p class="has-text-danger"><strong>总量：</strong> {{getHumenTotal(total)}}</p>
  <line-chart :chart-data="chartData" :height="120"></line-chart>
  <b-table ref="table" :data="tableData">
    <b-table-column #default="props" field="cid" label="渠道ID" width="80" numeric>
      {{ props.row.channel }}
    </b-table-column>
    <b-table-column #default="props" field="name" label="渠道名" width="200">
      {{ props.row.name }}
    </b-table-column>
    <b-table-column #default="props" field="date" label="日期" width="120">
      {{ props.row.date }}
    </b-table-column>
    <b-table-column #default="props" field="num" label="数量">
      {{ props.row.num }}
    </b-table-column>
  </b-table>
</div>
</template>

<script>
/**
 * 七天小游戏专用的统计界面
 */
import { nextChartColor, fromNow } from '@/core/util'
import { Duration } from 'luxon'
import cache from '@/core/cache'
import LineChart from '@/components/charts/LineChart'
import DateQuick from '@/components/DateQuick'
import ChannelSelector2 from '@/components/channel/ChannelSelector2'

export default {
  name: 'analytics',
  components: { LineChart, ChannelSelector2, DateQuick },
  data: function () {
    return {
      // 图表数据
      chartData: null,
      // 表格数据
      tableData: [],
      minDate: new Date(2018, 4, 12),
      maxDate: new Date(),
      fromDate: fromNow({ days: -7 }),
      toDate: new Date(),
      total: 0,
      isLoading: false,
      // 数据中包含的 channel id
      channelsInStat: []
    }
  },
  computed: {
    selectedChannels () {
      const cs = this.$refs.channelSelector
      if (cs) {
        return cs.selectedChannels
      }
      return []
    },
    regional () {
      return cache.getR(true)
    }
  },
  methods: {
    getHumenTotal (num) {
      return num
    },
    getHumenTime (seconds, unit) {
      const humenTime = Duration.fromMillis(seconds * 1000).as(unit)
      return humenTime.toFixed(1)
    },
    getChannelName (cid) {
      if (this.$refs.channelSelector) {
        return this.$refs.channelSelector.channelsName[cid]
      }
      return ''
    },
    onDateQuick (date) {
      this.fromDate = date.fromDate
      this.toDate = date.toDate
    },
    onDateInput (date) {
      this.getRecord()
    },
    onChannelChange (channel) {
      this.getRecord()
    },
    getUrlObj () {
      const channnelParam = this.selectedChannels
      const url = '/analy/register/'
      if (channnelParam.length === 0) {
        return [url, null]
      }
      const filterObj = { channel: JSON.stringify(channnelParam) }
      if (this.fromDate) {
        filterObj.from_date = this.formatDate(this.fromDate)
      }
      if (this.toDate) {
        filterObj.to_date = this.formatDate(this.toDate)
      }
      return [url, filterObj]
    },
    sortChannelAndDate (a, b) {
      const channelComp = b.channel - a.channel
      if (channelComp === 0) {
        const atime = this.fromDateFormat(a.date).getTime()
        const btime = this.fromDateFormat(b.date).getTime()
        return btime - atime
      }
      return channelComp
    },
    buildChartData (labels, datasets) {
      labels = labels || []
      datasets = datasets || []
      return { labels, datasets }
    },
    buildData (data) {
      // 数据需要重新进行组合才能在图表中使用
      const stat = data.stat
      const dataSetsObj = {}
      let channels = {}
      let total = 0
      stat.forEach(item => {
        // 获得不重复的 channel
        channels[item.channel] = true
        if (!dataSetsObj[item.date]) {
          dataSetsObj[item.date] = {}
        }
        dataSetsObj[item.date][item.channel] = item.num
        item.name = this.getChannelName(item.channel)
        total += item.num
      })
      this.tableData = stat
      this.tableData.sort(this.sortChannelAndDate)
      this.total = total

      // 创建图表需要的数据
      const labels = Object.keys(dataSetsObj).sort()
      const datasets = []
      channels = Object.keys(channels)
      for (let i = 0; i < channels.length; i++) {
        const cid = channels[i]
        const dataset = { label: this.getChannelName(cid), data: [], borderColor: nextChartColor(i) }
        for (const label of labels) {
          const day = dataSetsObj[label]
          dataset.data.push(day[cid] || 0)
        }
        datasets.push(dataset)
      }
      this.chartData = { labels, datasets }
      console.log('chartData:%o', this.chartData)
    },
    clearRecord () {
      this.chartData = this.buildChartData()
      this.tableData = []
      this.channelsInStat = []
      this.total = 0
    },
    async getRecord () {
      console.log('isLoading: %s', this.isLoading)
      if (this.isLoading) {
        return
      }
      if (!this.hub.checkFilters([this.regional, '区服'])) {
        this.clearRecord()
        return
      }
      const [url, filterObj] = this.getUrlObj()
      if (filterObj === null) {
        return
      }
      this.isLoading = true
      let data = null
      try {
        data = await this.mjp.get(url, filterObj)
        this.buildData(data)
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    }
  }
}
</script>

<style>
#analyserverday {
  padding: 12px;
}
</style>
